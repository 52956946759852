


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'navLayout'
})
export default class InsNavLayout extends Vue {
  @Prop() private navData!: object;
  @Prop({ default: 1 }) private level!: number;

  toUrl (url) {
    if (url) {
      window.location.href = url;
    }
  }
  showSta (n, data) {
    data.forEach(item => {
      item.ShowList = false;
    });
    n.ShowList = true;
    this.$nextTick(() => {
      $('.nav_menu').mouseleave(function () {
        $('.nav_s').hide();
        $('.el-icon-caret-top').hide();
        n.ShowList = false;
      });
    });
  }
  To (n) {
    if (n.Url !== '') {
      return n.Url;
    } else {
      return n.Type === 1 ? '/cms/catDetail/' + n.Value.Id : n.Type === 2 ? '/CMS/content/' + n.Value.Id : n.Type === 3 ? '/RegNPay/Form/' + n.Value.Id : (n.Type === 4 && n.Value.Id) ? '/product/cat/' + n.Value.Id : n.Type === 5 ? '/product/search/+?attrs=' + JSON.stringify([{ Id: parseInt(n.Value.Id), Vals: [] }]) + '&type=0' : '';
    }
  }
  get lang () {
    return this.$store.state.lang;
  }
}
